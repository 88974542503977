import {

    Component,
    DestroyRef,
    inject,
    OnDestroy,
    OnInit
} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {
  catchError,
  debounceTime, EMPTY,
  exhaustMap, map,
  Subject, switchMap,
} from 'rxjs';
import {
  notNull,
} from '../../shared/utils';
import {FormHelperService} from "../../shared/services/form-helper.service";
import { models } from 'src/app/shared/models';
import {MessageService} from "primeng/api";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {gql} from "../../../gql";
import {FormSaveHelper} from "@vasio-nl/valow";
import {ErrorService} from "../../shared/services/error.service";

const RESET_OTP_MUTATION = gql(/* GraphQL */`
  mutation UserComponent_reset_otp($userId: ID!) {
    resetOtp(userId: $userId)
  }
`);

const USERS_SEARCH_QUERY = gql(/* GraphQL */`
  query UserComponent_search($search: String) {
    users(search: $search, newcomerUser: false) {
      id
      email
    }
  }
`);

@Component({
  selector: 'app-newcomer',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss', '../DetailPageHeader.scss']
})
export class UserComponent implements OnInit {
    public isNew = true;

    destroyRef = inject(DestroyRef);
    formContainer = this.formHelperService.getFormContainer(models.User, (fb) => ({
        id: fb.modelField('id'),
        firstName: fb.modelField('firstName'),
        lastName: fb.modelField('lastName'),
        email: fb.modelField('email'),
        newcomerLocations: fb.modelField('newcomerLocations'),
    }));
    public form = this.formContainer.form;
    public saveHelper = new FormSaveHelper(this.formContainer, this.messageService);

    public otpActive = false;
    public savingOtp = false;
    public resetOTP$ = new Subject();

    // Used for routing to existing users in wegwijzer to add newcomer locations.
    existingUserId: string | null = null;

    constructor(private formHelperService: FormHelperService, private errorService: ErrorService, private router: Router, private route: ActivatedRoute, private apollo: Apollo, private messageService: MessageService) {

        // Idea's:
        // Model + form fields into a seperate object make it way easier to use.
        // Instead of creating a container we could also wrap it into small utility functions.
        // We probably can't put how do we handle errors on the long term. The helper components should handle this.


    }

    ngOnInit(): void {
        const userId = this.route.snapshot.params['id'];
        this.isNew = !userId;

        if (userId) {
            this.form.disable();

            // TODO: This misses error handling.
            this.formHelperService.getData(this.formContainer.model, {
                ...this.formContainer.queryFields,
                otpActive: true
            }, userId).pipe(
                catchError(err => this.errorService.HandleGraphQLError('Ophalen van gebruiker')),
                takeUntilDestroyed(this.destroyRef)
            ).subscribe(data => {
                if(!data.user) {
                    this.messageService.add({severity: 'error', summary: 'Error', detail: 'Kon gebruiker niet vinden.'});
                    return;
                }
                this.otpActive = data.user.otpActive;

                const preparedData = this.formContainer.prepareDataForForm(data.user);
                this.formHelperService.updateFormFromModel(this.form, preparedData);
                this.form.enable();
            });
        }

        this.saveHelper.onSave$.pipe(
          takeUntilDestroyed(this.destroyRef)
        ).subscribe((response) => {
          if(response.errors.length == 0) {
            this.router.navigate(['/users-overzicht']);
          }
        });

        this.resetOTP$.pipe(
            exhaustMap(() => {
              this.savingOtp = true;
              return this.apollo.mutate({
                mutation: RESET_OTP_MUTATION,
                variables: {userId: notNull(this.form.controls.id.value)}
              });
            }),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(r => {
          this.savingOtp = false;
          this.otpActive = false;

          this.messageService.add({severity: 'success', summary: 'Gelukt!', detail: '2FA is gereset.'});
        });

        if(this.isNew) {
            this.form.controls.email.valueChanges.pipe(
                debounceTime(500),
                switchMap(email => {
                    if (!email) {
                        return EMPTY;
                    }

                    return this.apollo.query({
                        query: USERS_SEARCH_QUERY,
                        variables: {
                            search: email
                        }
                    }).pipe(
                        map(result => [result, email] as const)
                    );
                })
            ).subscribe(([result, email]) => {
                const existingUser = result.data.users.find(u => u.email == email);
                if (existingUser) {
                    this.existingUserId = existingUser.id;
                } else {
                    this.existingUserId = null;
                }
            });
        }
    }
}

